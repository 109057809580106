<template>
  <Layout :pagetitle="'Categories'">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h4 class="card-title mb-0">Categorias</h4>
            <button
              :class="`btn btn-sm btn-rounded waves-effect waves-light col-3 col-lg-1 btn-${company.color ? company.color : 'primary'}`"
              @click="setUpdateCategory(null, null)"
              data-bs-toggle="modal"
              data-bs-target=".modal-category"
            >
              <span>Novo</span>
            </button>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(category, index) in categories" :key="category._id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ category.name }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-light btn-sm"
                        @click="setUpdateCategory(category._id, category.name)"
                        data-bs-toggle="modal"
                        data-bs-target=".modal-category"
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="d-flex justify-content-end">
        <button 
          :class="`btn btn-rounded waves-effect waves-light col-6 col-md-2 btn-${company.color ? company.color : 'primary'}`"
          @click="$router.push({ name: 'companyPrincipal' })"
        >
          <span>Voltar</span>
        </button>
      </div>
    </div>
    <!-- modal-update-category -->
    <div
      class="modal fade modal-category"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mySmallModalLabel">
              {{ categoryId ? "Editar Categoria" : "Nova Categoria" }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="closeButton"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mt-2">
              <label class="form-label">Nome</label>
              <input class="form-control" type="text" v-model="categoryName" />

              <div class="d-flex justify-content-center" v-if="isLoading">
                <div :class="`spinner-border m-1 mt-3 text-${company.color ? company.color : 'primary'}`" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

              <button
                v-else
                :class="`btn btn-rounded waves-effect waves-light col-5 col-md-4 mt-4 w-100 btn-${company.color ? company.color : 'primary'}`"
                @click="saveCategory()"
              >
                <span>Salvar</span>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal-update-category -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      company: JSON.parse(localStorage.getItem("company")),
      categories: [],
      categoryId: null,
      categoryName: null,
    };
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  created() {},
  async mounted() {
    if (this.$route.params && this.$route.params.categories) {
      this.categories = JSON.parse(this.$route.params.categories);
    } else {
      if (this.company._id) {
        const categories = await this.$utils.getByCompany(
          "category",
          this.company._id
        );
        if (categories) {
          this.categories = categories;
        }
      }
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    setUpdateCategory(id, name) {
      this.categoryId = id;
      this.categoryName = name;
    },
    updateArrayCategories(id, name) {
      const idx = this.categories.findIndex((el) => el._id === id);
      if (idx > -1) {
        this.categories[idx].name = name;
      }
    },
    saveCategory() {
      if (!this.categoryName || this.categoryName.length < 3) {
        this.$customAlert({
          type: "warning",
          msg: "Informe um nome",
        });
        return;
      }

      if (this.categoryId) {
        this.setLoading(true);
        this.$api
          .put(`category/${this.categoryId}`, { name: this.categoryName })
          .then(() => {
            this.setLoading(false);
            this.updateArrayCategories(this.categoryId, this.categoryName);
            this.setUpdateCategory(null, null);
            this.$customAlert({
              type: "success",
              msg: "Categoria atualizada com sucesso",
            });
            const closeButton = this.$refs.closeButton;
            closeButton.click();
          })
          .catch((err) => {
            this.setLoading(false);
            this.$customAlert({
              type: "error",
              msg: err?.data?.message || "Erro ao atualizar categoria",
            });
          });
      } else {
        const payload = {
          idCompany: this.company._id,
          name: this.categoryName,
        };
        this.setLoading(true);
        this.$api
          .post("category", payload)
          .then((res) => {
            this.setLoading(false);
            this.categories.push({
              _id: res.data._id,
              idCompany: res.data.idCompany,
              name: res.data.name,
            });
            this.setUpdateCategory(null, null);
            this.$customAlert({
              type: "success",
              msg: "Categoria criada com sucesso",
            });
            const closeButton = this.$refs.closeButton;
            closeButton.click();
          })
          .catch((err) => {
            this.setLoading(false);
            this.$customAlert({
              type: "error",
              msg: err?.data?.message || "Erro ao criar categoria",
            });
          });
      }
    },
  },
};
</script>

<style scoped></style>
